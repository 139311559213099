import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";


import ShopDashboard from './Components/ShopDashboard';
import CustomerDashboard from './Components/CustomerDashboard';
import CompanyDashboard from './Components/CompanyDashboard';
import Login from './Components/Login';
import Register from './Components/Register';
import StartPage from './Components/StartPage';
import AcceptCoupons from './Components/AcceptCoupons';
import Dashboard from './Components/Dashboard';

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      try {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            setLoggedInUser(user.uid); // Update logged-in state
          }
        });
      }
      catch (error) {
        console.log(error.message)
      }
    }
    checkAuth();
  }, [loggedInUser, setLoggedInUser]);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route
            path="/login"
            element={<Login setLoggedInUser={setLoggedInUser} loggedInUser={loggedInUser} />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard/">
            <Route index element={<Dashboard />} />
            <Route
              path="shop"
              element={
                loggedInUser ? (
                  <div>
                    <ShopDashboard loggedInUser={loggedInUser} />
                  </div>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="company"
              element={
                loggedInUser ? (
                  <div>
                    <CompanyDashboard loggedInUser={loggedInUser} />
                  </div>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="customer"
              element={
                loggedInUser ? (
                  <div>
                    <CustomerDashboard loggedInUser={loggedInUser} />
                  </div>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          </Route>
          <Route path="/acceptCoupons" element={<AcceptCoupons loggedInUser={loggedInUser} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
