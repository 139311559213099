import React, { useState, useEffect } from "react";
import { database } from "../FirebaseConfig";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "./Dashboard.css"; // Import CSS file

const Dashboard = () => {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const user = firebase.auth().currentUser;
        if (!user) {
          navigate("/login");
          return;
        }

        const snapshot = await database.ref(`users/${user.uid}`).once("value");
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserRole(userData.role);
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user role:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, [navigate]);

  useEffect(() => {
    if (userRole) {
      setTimeout(() => {
        navigate(`/dashboard/${userRole}`);
      }, 1234);
    }
  }, [userRole, navigate]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-card">
        {loading ? (
          <h1 className="loading-text">Loading...</h1>
        ) : userRole ? (
          <>
            <h1 className="redirect-text">
              Redirecting to {userRole} dashboard...
            </h1>
            <button className="redirect-button" onClick={() => navigate(`/dashboard/${userRole}`)}>
              🚀 Go Now
            </button>
          </>
        ) : (
          <h1 className="error-text">❌ Role not assigned!</h1>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
