import React, { useState, useEffect } from 'react';
import { database } from '../FirebaseConfig';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import './AcceptCoupons.css'; // Import custom CSS

const AcceptCoupons = ({ loggedInUser }) => {
    const [customerId, setCustomerId] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [couponId, setCouponId] = useState('');
    const [couponData, setCouponData] = useState(null);
    const [barista, setBarista] = useState('');
    const [shopCode, setShopCode] = useState('');
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


    useEffect(() => {
        if (loggedInUser) {
            setShopCode(loggedInUser.slice(0, 3) + loggedInUser.slice(-3));
        }
    }, [loggedInUser]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const uid = params.get('uid');
        const cid = params.get('cid');
        const kid = params.get('kid');
        const kcode = params.get('kcode');

        if (uid && cid && kid && kcode) {
            setCustomerId(uid);
            setCompanyId(cid);
            setCouponId(kid);
            setCouponCode(kcode);

            // Fetch coupon data from Firebase
            database.ref(`company_coupons/${cid}/${kid}`)
                .once('value')
                .then((snapshot) => {
                    const couponData = snapshot.val();
                    if (couponData && couponData.coupon_code === kcode && parseInt(couponData.coupon_count[uid]) > 0) {
                        setCouponData(couponData);
                    } else {
                        setAlertMessage({ text: 'Invalid or expired coupon.', type: 'danger' });

                    }
                })
                .catch((error) => {
                    console.log('Error fetching coupon:', error.message);
                });
        } else {
            setAlertMessage({ text: 'Invalid URL parameters', type: 'danger' });

        }
    }, [loggedInUser]);

    const acceptOrder = async () => {
        try {
            if (!customerId || !couponData) {
                setAlertMessage({ text: 'Invalid customer ID or coupon data', type: 'danger' });
                return;
            }

            const matchRef = database.ref(`shop_companies/${shopCode}/${companyId}`);
            const matchSnapShot = await matchRef.once('value');
            const match = matchSnapShot.val();

            if (match === true) {
                // Decrease coupon_count by 1
                await database.ref(`company_coupons/${companyId}/${couponId}/coupon_count/${customerId}`).transaction((count) => {
                    if (count > 0) {
                        const customerCouponRef = database.ref(`customer_coupons/${customerId}`);
                        const couponToUpdateRef = customerCouponRef.orderByChild('coupon_id').equalTo(couponId);
                        couponToUpdateRef.once('value', (snapshot) => {
                            snapshot.forEach((childSnapshot) => {
                                const coupon = childSnapshot.val();
                                if (coupon.coupon_count > 0) {
                                    childSnapshot.ref.update({ coupon_count: coupon.coupon_count - 1 });
                                }
                            });
                        });
                        setAlertMessage({ text: 'Thanks For Ordering', type: 'success' });
                        return count - 1;
                    } else {
                        return 0;
                    }
                });

                // Save order details
                await database.ref(`orders/${companyId}/`).push({
                    company_id: companyId,
                    customer_id: customerId,
                    coupon_code: couponCode,
                    barista: barista,
                    accepted_at: new Date().toISOString(),
                });
            } else {
                setAlertMessage({ text: 'INVALID: Auth Failed', type: 'danger' });
            }

            setCustomerId('');
            setCouponCode('');
            setCompanyId('');
            setCouponId('');
            setCouponData(null);
        } catch (error) {
            console.log('Error accepting order:', error.message);
        }
    };

    const rejectOrder = () => {
        setCustomerId('');
        setCouponCode('');
        setCompanyId('');
        setCouponId('');
        setCouponData(null);
        setAlertMessage({ text: 'Order Rejected', type: 'danger' });

    };

    return (
        <Container className="accept-coupons-container">
            <h2 className="text-center mt-4">Shop Dashboard</h2>
            {customerId ? (
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Card className="p-4 shadow-lg">
                            <h3 className="text-primary text-center mb-3">Coupon Details</h3>
                            <p><strong>Coupon Code:</strong> {couponCode}</p>
                            <p><strong>Discount:</strong> {couponData?.discount}</p>
                            <p><strong>Validity:</strong> {couponData?.validity}</p>
                            <p><strong>Coupon ID:</strong> {couponId}</p>
                            <Form>
                                <Form.Group controlId="barista">
                                    <Form.Label>Enter Your Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Name"
                                        value={barista}
                                        onChange={(e) => setBarista(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Form>
                            <div className="button-group mt-4">
                                <Button variant="success" className="me-3" onClick={acceptOrder}>
                                    <strong>Accept</strong>
                                </Button>
                                <Button variant="danger" onClick={rejectOrder}>
                                    <strong>Reject</strong>
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row className="justify-content-center mt-4">
                    <Col md={6}>
                        {alertMessage.text && <Alert variant={alertMessage.type}>{alertMessage.text}</Alert>}
                        <p className="text-muted text-center">Processing URL parameters...</p>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default AcceptCoupons;
